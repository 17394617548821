import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useRef } from 'react';
import Button from '~components/common/Button';
import Container from '~components/common/Container';
import Layout from '~components/layouts/Default';
import P from '~components/typography/P';
import Divider from '~components/sections/Divider';
import { CheckCircleIcon } from '@heroicons/react/solid';
import Grid from '~components/common/Grid';
import Testmonials from '~components/sections/Testmonials';
import { customHeadingClasses } from '../utils/helpers';
import useAnimations, { AnimationConfigItem, animations } from '../hooks/useAnimations';
import { svgs } from '~data/svgs';
import Feature from '~components/common/Feature';
import BlurbNumber from '~components/common/BlurbNumber';
import Quiz from '~components/quiz/Quiz';
import Faq from '~components/sections/Faq';
import SvgAppStore from '~components/common/svgs/SvgAppStore';
import SvgPlayStore from '~components/common/svgs/SvgPlayStore';
import Image from '~components/common/Image';

// @ts-ignore
export default function Page({ data, location }) {
  const headerImageRef = useRef(null);
  const headerTitleRef = useRef(null);
  const headerDividerRef = useRef(null);
  const headerTitle2Ref = useRef(null);
  const headerTextRef = useRef(null);
  const headerButtonRef = useRef(null);

  const logo1Ref = useRef(null);
  const logo2Ref = useRef(null);
  const logo3Ref = useRef(null);
  const logo4Ref = useRef(null);
  const logo5Ref = useRef(null);

  const introFirstTitleRef = useRef(null);
  const introFirstText1Ref = useRef(null);
  const introFirstText2Ref = useRef(null);
  const introFirstText3Ref = useRef(null);
  const introFirstText4Ref = useRef(null);

  const introSecondTitleRef = useRef(null);
  const introSecondDividerRef = useRef(null);
  const introSecondTextRef = useRef(null);

  const solutionsTitleRef = useRef(null);
  const solutionsText1Ref = useRef(null);
  const solutionsText2Ref = useRef(null);
  const solutionsText3Ref = useRef(null);
  const solutionsImageRef = useRef(null);
  const solutionsTextDivRef = useRef(null);

  const appTitleRef = useRef(null);
  const appAppStoreRef = useRef(null);
  const appGooglePlayRef = useRef(null);
  const appTitle2Ref = useRef(null);
  const appTextRef = useRef(null);
  const appImageRef = useRef(null);

  const videoCoursesImageRef = useRef(null);
  const videoCoursesTitleRef = useRef(null);
  const videoCoursesTitle2Ref = useRef(null);
  const videoCoursesTextRef = useRef(null);
  const videoCoursesLogoRef = useRef(null);
  const videoCoursesButtonRef = useRef(null);

  const quizTitleRef = useRef(null);
  const quizComponentRef = useRef(null);

  const onlinePlanTitleRef = useRef(null);
  const onlinePlanTitle2Ref = useRef(null);
  const onlinePlanText1Ref = useRef(null);
  const onlinePlanText2Ref = useRef(null);
  const onlinePlanComponentRef = useRef(null);
  const onlinePlanFeature1Ref = useRef(null);
  const onlinePlanFeature2Ref = useRef(null);
  const onlinePlanFeature3Ref = useRef(null);
  const onlinePlanButtonRef = useRef(null);

  const testmonialComponentRef = useRef(null);

  const faqImageRef = useRef(null);
  const faqTitleRef = useRef(null);
  const faqComponentRef = useRef(null);

  const animationConfig: AnimationConfigItem[] = [
    {
      ref: headerImageRef,
      animationOptions: animations.scaleIn
    },
    {
      ref: headerTitleRef,
      animationOptions: animations.fadeInDown
    },
    {
      ref: headerDividerRef,
      animationOptions: animations.fadeInUp
    },
    {
      ref: headerTitle2Ref,
      animationOptions: animations.fadeInUp
    },
    {
      ref: headerTextRef,
      animationOptions: animations.fadeInUp
    },
    {
      ref: headerButtonRef,
      animationOptions: animations.fadeInUp
    },
    {
      ref: logo1Ref,
      animationOptions: animations.fadeInUp
    },
    {
      ref: logo2Ref,
      animationOptions: animations.fadeInUp
    },
    {
      ref: logo3Ref,
      animationOptions: animations.fadeInUp
    },
    {
      ref: logo4Ref,
      animationOptions: animations.fadeInUp
    },
    {
      ref: logo5Ref,
      animationOptions: animations.fadeInUp
    },
    {
      ref: introFirstTitleRef,
      animationOptions: animations.fadeInUp
    },
    {
      ref: introFirstText1Ref,
      animationOptions: animations.fadeInLeft
    },
    {
      ref: introFirstText2Ref,
      animationOptions: animations.fadeInRight
    },
    {
      ref: introFirstText3Ref,
      animationOptions: animations.fadeInLeft
    },
    {
      ref: introFirstText4Ref,
      animationOptions: animations.fadeInRight
    },
    {
      ref: introSecondTitleRef,
      animationOptions: animations.fadeInUp
    },
    {
      ref: introSecondDividerRef,
      animationOptions: animations.fadeInUp
    },
    {
      ref: introSecondTextRef,
      animationOptions: animations.fadeInUp
    },
    {
      ref: solutionsTitleRef,
      animationOptions: animations.fadeInUp
    },
    {
      ref: solutionsText1Ref,
      animationOptions: animations.fadeInUp
    },
    {
      ref: solutionsText2Ref,
      animationOptions: animations.fadeInUp
    },
    {
      ref: solutionsText3Ref,
      animationOptions: animations.fadeInUp
    },
    {
      ref: solutionsImageRef,
      animationOptions: animations.fadeInLeft
    },
    {
      ref: solutionsTextDivRef,
      animationOptions: animations.fadeInRight
    },

    {
      ref: appTitleRef,
      animationOptions: animations.fadeInLeft
    },
    {
      ref: appAppStoreRef,
      animationOptions: animations.fadeInRight
    },
    {
      ref: appGooglePlayRef,
      animationOptions: animations.fadeInRight
    },
    {
      ref: appTitle2Ref,
      animationOptions: animations.fadeInLeft
    },
    {
      ref: appTextRef,
      animationOptions: animations.fadeInLeft
    },
    {
      ref: appImageRef,
      animationOptions: animations.fadeInRight
    },
    {
      ref: videoCoursesImageRef,
      animationOptions: animations.fadeInRight
    },
    {
      ref: videoCoursesTitleRef,
      animationOptions: animations.fadeInUp
    },
    {
      ref: videoCoursesTitle2Ref,
      animationOptions: animations.fadeInLeft
    },
    {
      ref: videoCoursesTextRef,
      animationOptions: animations.fadeInLeft
    },
    {
      ref: videoCoursesLogoRef,
      animationOptions: animations.fadeInLeft
    },
    {
      ref: videoCoursesButtonRef,
      animationOptions: animations.fadeInLeft
    },
    {
      ref: quizTitleRef,
      animationOptions: animations.fadeInUp
    },
    {
      ref: quizComponentRef,
      animationOptions: animations.fadeInUp
    },
    {
      ref: onlinePlanTitleRef,
      animationOptions: animations.fadeInUp
    },
    {
      ref: onlinePlanTitle2Ref,
      animationOptions: animations.fadeInUp
    },
    {
      ref: onlinePlanText1Ref,
      animationOptions: animations.fadeInUp
    },
    {
      ref: onlinePlanText2Ref,
      animationOptions: animations.fadeInUp
    },
    {
      ref: onlinePlanComponentRef,
      animationOptions: animations.fadeInUp
    },
    {
      ref: onlinePlanFeature1Ref,
      animationOptions: animations.fadeInUp
    },
    {
      ref: onlinePlanFeature2Ref,
      animationOptions: animations.fadeInUp
    },
    {
      ref: onlinePlanFeature3Ref,
      animationOptions: animations.fadeInUp
    },
    {
      ref: onlinePlanButtonRef,
      animationOptions: animations.fadeInUp
    },
    {
      ref: testmonialComponentRef,
      animationOptions: animations.fadeInUp
    },
    {
      ref: faqImageRef,
      animationOptions: animations.fadeInLeft
    },
    {
      ref: faqTitleRef,
      animationOptions: animations.fadeInLeft
    },
    {
      ref: faqComponentRef,
      animationOptions: animations.fadeInRight
    }
  ];

  useAnimations(animationConfig);

  const headerImage = getImage(data.header);
  const logoKinetic = getImage(data.logoKinetic);
  const logoTechniker = getImage(data.logoTk);
  const logoContinental = getImage(data.logoContinentale);
  const logoHansefit = getImage(data.logoHansefit);
  const logoBkkHerkules = getImage(data.logoBkkHerkules);
  const imageApp = getImage(data.imageApp);
  const imageKursplan = getImage(data.imageKursplan);
  const imageVideokurs = getImage(data.imageVideokurs);

  return (
    <Layout
      location={location}
      title="✅ Entspannungshelden - entspannen wann und wo du willst"
      desc="Mit den Entspannungshelden entspannst du Orts- und Zeitunabhängig, ganz egal, ob du EinsteigerIn oder Profi bist. Genieße volle Flexibilität."
      active="Start"
      minimalFooter
    >
      <div className="overflow-hidden">
        <div className="relative">
          <div className="absolute inset-0 h-full w-full object-cover" ref={headerImageRef}>
            {headerImage && <GatsbyImage image={headerImage} className="absolute inset-0 h-full w-full object-cover" alt="" />}
          </div>
          <div className="absolute inset-0 h-full w-full bg-black/30 object-cover"></div>
          <div className="relative bg-black/30">
            <div className="mx-auto max-w-4xl px-4 py-16 text-center text-white md:py-32">
              <h1 className={`mb-8 ${customHeadingClasses.h1}`} ref={headerTitleRef}>
                Entspannung, wann und wo du willst. Und das für Lau?
              </h1>
              <div ref={headerDividerRef}>{svgs.dividerVertical('my-8 w-8 block')}</div>
              <p className={`mb-8 ${customHeadingClasses.h2}`} ref={headerTitle2Ref}>
                Kein Problem. Denn deine Krankenkasse zahlt.
              </p>
              <p className={`mb-8 ${customHeadingClasses.h4}`} ref={headerTextRef}>
                Unsere Präventionskurse sind nach § 20 zertifiziert. Beim Abschluss einer unserer Entspannungskurse erhältst du Zugriff auf all unsere Dienste.
              </p>

              <div ref={headerButtonRef}>
                <Button text="Erstattungscheck" to="/kurse/krankenkasse/" />
              </div>
            </div>
          </div>
        </div>

        <div className="overflow-hidden bg-brand-gray/10">
          <Grid cols={5} items="center" className="py-16">
            {logoKinetic && (
              <div className="flex items-center justify-center" ref={logo1Ref}>
                <GatsbyImage image={logoKinetic} alt="Kinetic" />
              </div>
            )}
            {logoTechniker && (
              <div className="flex items-center justify-center" ref={logo2Ref}>
                <GatsbyImage image={logoTechniker} alt="Techniker Krankenkasse" />
              </div>
            )}
            {logoContinental && (
              <div className="flex items-center justify-center" ref={logo3Ref}>
                <GatsbyImage image={logoContinental} alt="Continentale" />
              </div>
            )}
            {logoHansefit && (
              <div className="flex items-center justify-center" ref={logo4Ref}>
                <GatsbyImage image={logoHansefit} alt="Hansefit" />
              </div>
            )}
            {logoBkkHerkules && (
              <div className="flex items-center justify-center" ref={logo5Ref}>
                <GatsbyImage image={logoBkkHerkules} alt="BKK Herkules" />
              </div>
            )}
          </Grid>
          <Divider className="rotate-180 text-gray-50" />
        </div>
        <div className="overflow-hidden">
          <Container>
            <h2 className={`mb-8 md:mb-16 lg:mb-32 ${customHeadingClasses.h2} text-center text-brand-gray`} ref={introFirstTitleRef}>
              Zählst du auch zu den Menschen..
            </h2>
            <Grid cols={2}>
              <div ref={introFirstText1Ref}>
                <p className={`text-brand-gray ${customHeadingClasses.h3} mb-4`}>... die sich unfassbar anstrengen?</p>
                <P>Du gibst dein bestes um den Alltag zu bestreiten, allen Terminen und Anforderungen gerecht zu werden. Doch es reicht einfach nicht? Es ist nie genug?</P>
              </div>
              <div ref={introFirstText2Ref}>
                <p className={`text-brand-gray ${customHeadingClasses.h3} mb-4`}>... die sich keine Pause erlauben?</p>
                <P>Du schaffst es einfach nicht zur Ruhe zu kommen? Kannst nach der Arbeit nicht mehr abschalten und hast das Gefühl alles lastet auf deinen Schultern? </P>
              </div>
              <div ref={introFirstText3Ref}>
                <p className={`text-brand-gray ${customHeadingClasses.h3} mb-4`}>... die es allen recht machen wollen?</p>
                <P>Für dich steht das Wohl der anderen im Vordergrund und du guckst erst einmal, dass es allen anderen gut geht, bevor du nach dir schauen kannst.</P>
              </div>
              <div ref={introFirstText4Ref}>
                <p className={`text-brand-gray ${customHeadingClasses.h3} mb-4`}>... die sich um ihre Zukunft sorgen?</p>
                <P>Wie soll das nur weitergehen, ständig bist du müde und erschöpft. Was wenn du einen Fehler machst? Wie soll es bloß weitergehen? Steht am Ende gar das Burnout?</P>
              </div>
            </Grid>
          </Container>
        </div>

        <div className="overflow-hidden bg-brand-green/50">
          <Divider className="text-gray-50" />
          <Container>
            <div className="mx-auto max-w-4xl px-4 py-16 text-center text-brand-gray md:py-32">
              <h2 className={`mb-8 ${customHeadingClasses.h2}`} ref={introSecondTitleRef}>
                Rauben diese Gedanken deinen Schlaf?
              </h2>
              <div ref={introSecondDividerRef}>{svgs.dividerVertical('my-8 w-8 block')}</div>
              <div ref={introSecondTextRef}>
                <p className={`mb-8 ${customHeadingClasses.h3}`}>Gute Nachrichten:</p>
                <P>
                  Du bist nicht allein. Auch ich kenne diese Gedanken nur zu gut und bin durch die Erschöpfung gegangen, in dem sich jeder Schritt anfühlte wie eine Sumpfwanderung. Doch aus diesem Sumpf ist mittlerweile ein stabiles
                  Fundament, auf dem ich mich frei und sicher bewegen kann. Und du kannst das auch!
                </P>
              </div>
            </div>
          </Container>
          <Divider className="rotate-180 text-gray-50" />
        </div>

        <div className="overflow-hidden">
          <Container>
            <div className="mx-auto max-w-4xl px-4 py-16 text-center text-brand-gray md:py-32">
              <h2 className={`${customHeadingClasses.h2}`} ref={solutionsTitleRef}>
                Mit den Entspannungshelden wirst du...
              </h2>
            </div>
            <Grid cols={3} className="mb-16 md:mb-32">
              <div ref={solutionsText1Ref}>
                <BlurbNumber
                  title="Entspannen lernen"
                  text="Du lernst mehrere Verfahren kennen, die zu deinem Alltag passen. Ich konnte schon viele in die Entspannung führen, die von sich behaupteten sie könnten nicht entspannen."
                  number={1}
                  bg="bg-brand-green text-white"
                />
              </div>
              <div ref={solutionsText2Ref}>
                <BlurbNumber
                  title="Regelmäßig entspannen"
                  text="Du erkennst den Stellenwert der Pause und planst dir regelmäßige Pausen und Me-Time ein. Du wirst dir selber wichtiger und lernst dir mit gutem Gefühl etwas gutes zu tun."
                  number={2}
                  bg="bg-brand-green text-white"
                />
              </div>
              <div ref={solutionsText3Ref}>
                <BlurbNumber
                  title="mehr Energie bekommen"
                  text="Durch die neuen Routinen und deine regelmäßige Praxis gewinnst du Einheit für Einheit mehr Energie zurück und hast so mehr Kraft für schöne Dinge, auch neben der Arbeit."
                  number={3}
                  bg="bg-brand-green text-white"
                />
              </div>
            </Grid>
            <Grid cols={2}>
              <div ref={solutionsImageRef} className="flex justify-center">
                <Image imageData={data.imageJohannes} alt="Johannes Förster" className="animate-shake-vertical" />
              </div>
              <div className="text-brand-gray" ref={solutionsTextDivRef}>
                <h3 className={`mb-8 ${customHeadingClasses.h3}`}>Werde Entspannungsheld:in</h3>
                <P className="mb-4">Du kannst sofort beginnen und noch heute starten. Dabei entscheidest du, wie du beginnen möchtest. </P>
                <P>Natürlich kannst du jederzeit zwischen all den Angeboten switchen oder sie miteinander kombinieren.</P>
              </div>
            </Grid>
          </Container>
        </div>

        <div className="overflow-hidden bg-brand-green/50">
          <Divider className="text-gray-50" />
          <Container>
            <Grid cols={2} items="center">
              <div className="text-brand-gray">
                <h2 className={`mb-8 ${customHeadingClasses.h2}`} ref={appTitleRef}>
                  Was erwartet dich?
                </h2>
                <div className="mb-4 grid grid-cols-1 gap-4 md:flex">
                  <a href="https://apps.apple.com/de/app/entspannungshelden-app/id6479883365" target="_blank" rel="noreferrer noopener" ref={appAppStoreRef}>
                    <SvgAppStore />
                  </a>

                  <a href="https://play.google.com/store/apps/details?id=com.entspannungshelden.app" target="_blank" rel="noreferrer noopener" ref={appGooglePlayRef}>
                    <SvgPlayStore />
                  </a>
                </div>
                <h3 className={`mb-8 ${customHeadingClasses.h3}`} ref={appTitle2Ref}>
                  Entspannung per APP! Volle Flexibilität
                </h3>
                <div ref={appTextRef}>
                  <P className="mb-4">Nutze die Entspannungshelden App für den Idealen Einstieg in deine Entspannungspraxis und erhalte Einblick in die verschiedenen Bereiche der Entspannung.</P>
                  <P>Verschiedene Expert:innen aus Psychologie, Partnerschaft und Beziehung, Entspannung und Yoga begleiten dich beim Aufbau deiner Entspannungsroutine.</P>
                </div>
              </div>
              <div ref={appImageRef} className="flex justify-center">
                {imageApp && (
                  <a href="https://app.entspannungshelden.de" target="_blank">
                    <GatsbyImage image={imageApp} alt="Entspannungshelden App" className="animate-shake-vertical" />
                  </a>
                )}
              </div>
            </Grid>
          </Container>
          <Divider className="rotate-180 text-gray-50" />
        </div>

        <div className="overflow-hidden ">
          <Container>
            <div className="mx-auto max-w-4xl px-4 py-16 text-center text-brand-gray md:py-32">
              <h2 className={`mb-16 ${customHeadingClasses.h2}`} ref={videoCoursesTitleRef}>
                Unabhängig durch Videokurse
              </h2>
            </div>
            <Grid cols={2} items="center">
              <div ref={videoCoursesImageRef} className="flex justify-center">
                {imageVideokurs && (
                  <Link to="/kurse/">
                    <GatsbyImage image={imageVideokurs} alt="Entspannungshelden Videokurse" className="animate-shake-vertical" />
                  </Link>
                )}
              </div>
              <div className="text-brand-gray">
                <h3 className={`mb-8 ${customHeadingClasses.h3}`} ref={videoCoursesTitle2Ref}>
                  Flexibler Präventionskurs mit Krankenkassenzuschuss
                </h3>
                <div ref={videoCoursesTextRef} className="relative">
                  <P className="mb-4">Mit dem Autogenen Training und der Progressiven Muskelentspannung als Onlinekurs, bieten wir dir ein zertifiziertes und förderfähiges Kurskonzept. </P>
                  <P className="mb-4">In nur 8 Wochen lernst du das Autogene Training oder die Progressive Muskelentspannung. Nach Kursabschluss erhältst du dein Geld zurück.</P>
                </div>
                <div ref={videoCoursesButtonRef}>
                  <Button text="Zu den Kursen" to="/kurse/" />
                </div>
              </div>
            </Grid>
          </Container>
        </div>

        <div className="overflow-hidden bg-brand-green/50">
          <Divider className="text-gray-50" />
          <Container>
            <h2 className={`mb-16 ${customHeadingClasses.h2} text-center text-brand-gray`} ref={quizTitleRef}>
              Kein Plan welcher Kurs zu dir passt? Mach unser Quiz.
            </h2>
            <div className="mx-auto max-w-xl" ref={quizComponentRef}>
              <Quiz />
            </div>
          </Container>
          <Divider className="rotate-180 text-gray-50" />
        </div>

        <div className="overflow-hidden ">
          <Container>
            <div className="mx-auto max-w-4xl px-4 py-16 text-center text-brand-gray md:py-32">
              <h2 className={`mb-4 ${customHeadingClasses.h2}`} ref={onlinePlanTitleRef}>
                Onlinekursplan
                <br />
                6x die Woche live per Zoom
              </h2>
              <div ref={onlinePlanText1Ref}>
                <P>Wähle vollflexibel zwischen den Terminen als Einzeleinheit, oder profitiere durch den Abo Preis von 12,99 Euro.</P>
              </div>
            </div>
            <div ref={onlinePlanComponentRef} className="text-center">
              {imageKursplan && (
                <a href="https://www.eversports.de/widget/w/7992tN" target="_blank">
                  <GatsbyImage image={imageKursplan} alt="Entspannungshelden Onlinekursplan" />
                </a>
              )}
            </div>
            <div className="mx-auto max-w-4xl px-4 py-16 text-center text-brand-gray md:py-32">
              <h2 className={`mb-4 ${customHeadingClasses.h2}`} ref={onlinePlanTitle2Ref}>
                Alle Vorteile auf einen Blick
              </h2>
              <div ref={onlinePlanText2Ref}>
                <P>Einmal anmelden, alles nutzen wann und wo du willst.</P>
              </div>
            </div>
            <Grid cols={3} className="text-brand-gray">
              <div ref={onlinePlanFeature1Ref}>
                <Feature title="Aufzeichnung" text="Dadurch, dass die Kursstunden aufgezeichnet werden, erhältst du auch Zugriff auf vergangene Einheiten." svg={<CheckCircleIcon className="h-8 w-8" />} />
              </div>
              <div ref={onlinePlanFeature2Ref}>
                <Feature title="Günstig" text="Für dein Monatsabo zahlst du nur 12,99 Euro. Gegenüber den Einzelbuchungen sparst du 115 Euro pro Monat." svg={<CheckCircleIcon className="h-8 w-8" />} />
              </div>
              <div ref={onlinePlanFeature3Ref}>
                <Feature title="Qualität" text="Die kurzen Einheiten sind geprägt von hoher Ton und Bildqualität, professioneller Anleitung und fachkundigen Trainer:innen." svg={<CheckCircleIcon className="h-8 w-8" />} />
              </div>
            </Grid>
            <div ref={onlinePlanButtonRef} className="text-center">
              <Button text="Zum Kursplan" href="https://www.eversports.de/widget/w/7992tN" target="_blank" />
            </div>
          </Container>
        </div>

        <div ref={testmonialComponentRef}>
          <Testmonials />
        </div>

        <Container>
          <Grid cols={2}>
            <div ref={faqImageRef}>
              <Image imageData={data.imageFaq} alt="Häufige Fragen" />
            </div>
            <div ref={faqComponentRef}>
              <h2 className={`mb-8 ${customHeadingClasses.h3}`} ref={faqTitleRef}>
                Häufige Fragen
              </h2>
              <Faq />
            </div>
          </Grid>
        </Container>
      </div>
    </Layout>
  );
}

export const query = graphql`
  {
    header: file(relativePath: { eq: "pages/index/header.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }

    logoKinetic: file(relativePath: { eq: "partnerlogos/kinetic.png" }) {
      childImageSharp {
        gatsbyImageData(width: 240, quality: 100)
      }
    }
    logoTk: file(relativePath: { eq: "partnerlogos/tk.png" }) {
      childImageSharp {
        gatsbyImageData(width: 240, quality: 100)
      }
    }
    logoContinentale: file(relativePath: { eq: "partnerlogos/continentale.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200, quality: 100)
      }
    }
    logoHansefit: file(relativePath: { eq: "partnerlogos/hansefit.png" }) {
      childImageSharp {
        gatsbyImageData(width: 240, quality: 100)
      }
    }
    logoBkkHerkules: file(relativePath: { eq: "partnerlogos/bkk-herkules.png" }) {
      childImageSharp {
        gatsbyImageData(width: 240, quality: 100)
      }
    }
    imageApp: file(relativePath: { eq: "pages/index/app.png" }) {
      childImageSharp {
        gatsbyImageData(width: 480, quality: 80)
      }
    }
    imageFaq: file(relativePath: { eq: "pages/index/faq.png" }) {
      childImageSharp {
        gatsbyImageData(width: 840, quality: 80)
      }
    }
    imageJohannes: file(relativePath: { eq: "pages/index/johannes.png" }) {
      childImageSharp {
        gatsbyImageData(width: 480, quality: 80)
      }
    }
    imageKursplan: file(relativePath: { eq: "pages/index/kursplan.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1200, quality: 100)
      }
    }
    imageVideokurs: file(relativePath: { eq: "pages/index/videoKurse.png" }) {
      childImageSharp {
        gatsbyImageData(width: 840, quality: 80)
      }
    }
  }
`;
